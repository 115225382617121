<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__constructionlog">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.title" placeholder="施工部位" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              v-model="dateRange"
              type="daterange"
              value-format="yyyy-MM-dd"
              :range-separator="$t('datePicker.range')"
              :start-placeholder="$t('datePicker.start')"
              :end-placeholder="$t('datePicker.end')">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:constructionlog:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:constructionlog:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column label="施工部位" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.title }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="recordDate" label="登记日期" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="weather" label="天气情况" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="windSpeed" label="风向/风速" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="temperatures" label="最高/最低温度" width="140" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="constructionSituation" label="施工情况记录" show-overflow-tooltip></el-table-column>
        <el-table-column prop="qualitySafetyRecord" label="技术质量安全工作记录" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="160">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:constructionlog:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:constructionlog:delete')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:constructionlog:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './constructionlog-add-or-update'
import ViewPage from './constructionlog-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/constructionLog/page',
        getDataListIsPage: true,
        exportURL: '/opm/constructionLog/export',
        deleteURL: '/opm/constructionLog',
      },
      dataForm: {
        contractId: '',
        title: '',
        recordDateStart: '',
        recordDateEnd: '',
      },
      dateRange: null,
    }
  },
  watch: {
    dateRange (val) {
      if (val == null) {
        this.dataForm.recordDateStart = null
        this.dataForm.recordDateEnd = null
      } else {
        this.dataForm.recordDateStart = val[0]
        this.dataForm.recordDateEnd = val[1]
      }
    },
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 标段切换事件
    contractChange (data) {
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        if (!id) {
          this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        }
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>