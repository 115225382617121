<template>
  <el-dialog v-dialog-drag width='70%' :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="监表" prop="tab">
            <el-input v-model="dataForm.tab" placeholder="监表"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="监理机构" prop="supervise">
            <el-input v-model="dataForm.supervise" placeholder="监理机构"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签发人" prop="publisher">
            <el-input v-model="dataForm.publisher" placeholder="签发人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签发日期" prop="publishDate">
            <el-date-picker type="date" placeholder="签发日期" value-format="yyyy-MM-dd" v-model="dataForm.publishDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="内容" prop="content">
            <el-input
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 10}"
              placeholder="内容"
              v-model="dataForm.content">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签收人" prop="signer">
            <el-input v-model="dataForm.signer" placeholder="签收人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签收日期" prop="signDate">
            <el-date-picker type="date" placeholder="签收日期" value-format="yyyy-MM-dd" v-model="dataForm.signDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        code: '',
        tab: '',
        supervise: '',
        publisher: '',
        publishDate: '',
        signer: '',
        signDate: '',
        content: '',
      }
    }
  },
  computed: {
    dataRule () {
      return {
        supervise: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
        publishDate: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/superviseInstruction/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
      })
    },

    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/superviseInstruction/',this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
        })
      })
    },1000,{'leading': true,'trailing': false})
  }
}
</script>