<template>
  <el-dialog v-dialog-drag width="60%" :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="设备名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="设备名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="规格型号" prop="spec">
            <el-input v-model="dataForm.spec" placeholder="规格型号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="到场数量" prop="num">
            <el-input-number v-model="dataForm.num" data-unit="台/套/辆" placeholder="到场数量" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="到场日期" prop="arrivalDate">
            <el-date-picker :clearable="false" type="date" placeholder="到场日期" value-format="yyyy-MM-dd" v-model="dataForm.arrivalDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="生产日期" prop="producedDate">
            <el-date-picker :clearable="false" type="date" placeholder="生产日期" value-format="yyyy-MM-dd" v-model="dataForm.producedDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="有效期" prop="inDate">
            <el-date-picker :clearable="false" type="date" placeholder="有效期" value-format="yyyy-MM-dd" v-model="dataForm.inDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="功率或容量" prop="power">
            <el-input v-model="dataForm.power" placeholder="功率或容量">
              <template slot="append">kw/m³</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="生产厂家" prop="manufacturer">
            <el-input v-model="dataForm.manufacturer" placeholder="生产厂家"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="到期检验日期" prop="expirationDate">
            <el-date-picker :clearable="false" type="date" placeholder="到期检验日期" value-format="yyyy-MM-dd" v-model="dataForm.expirationDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="是否退场" prop="isExit">
            <el-switch v-model="dataForm.isExit" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="退场日期" prop="exitDate">
            <el-date-picker :clearable="false" type="date" placeholder="退场日期" value-format="yyyy-MM-dd" v-model="dataForm.exitDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注说明" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 2 }"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        name: '',
        spec: '',
        arrivalDate: '',
        num: '',
        producedDate: '',
        inDate: '',
        power: '',
        manufacturer: '',
        expirationDate: '',
        isExit: '',
        exitDate: '',
        remark: ''
      }
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    },
    exitState(){
      return dataForm.isExit
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/opm/equipmentInAndOut/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/equipmentInAndOut/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>