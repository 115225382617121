import Cookies from "js-cookie";

export const officeUtil = {
  docReadOnly: function (refId, typeId) {
    POBrowser.openWindow(`${window.SITE_CONFIG['poURL']}/docReadOnly`, 'width=1150px;height=900px;', JSON.stringify({
        accessToken: Cookies.get('access_token'),
        refId: refId,
        typeId: typeId
      })
    )
  },
  docEdit: function (refId, typeId) {
    POBrowser.openWindow(`${window.SITE_CONFIG['poURL']}/docEdit`, 'width=1150px;height=900px;', JSON.stringify({
        accessToken: Cookies.get('access_token'),
        refId: refId,
        typeId: typeId
      })
    )
  }
}