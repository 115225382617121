<template>
  <div>
    <vxe-table keep-source ref="myTbl"
        :loading="tableLoading"
        size="mini"
        :column-config="{resizable: true}"
        border
        show-overflow
        :row-config="{isCurrent: true, isHover: true}"
        :data="tableList"
        :max-height="tableHeight"
        @edit-closed="editClosedEvent"
        :cell-class-name="cellClassName"
        :edit-config="{enabled: $hasPermission('mps:sbs:edit') && locked < 2, trigger: 'click', mode: 'cell', showStatus: true, beforeEditMethod: beforeEditMethod}">
      <vxe-column type="seq" title="序号" width="70" fixed="left" align="center"></vxe-column>
      <vxe-column field="boqCode" title="清单编号" width="150" fixed="left"></vxe-column>
      <vxe-column field="boqName" title="清单名称" min-width="160" fixed="left"></vxe-column>
      <vxe-column field="unitName" title="单位" align="center" width="80"></vxe-column>
      <vxe-column field="typeId" title="类型" align="center" width="90">
        <template #default="{row}">
          <el-tag v-if="row.typeId == 1" type="success">合同清单</el-tag>
          <el-tag v-if="row.typeId == 2" type="danger">数量变更</el-tag>
          <el-tag v-if="row.typeId == 3" type="danger">新增清单</el-tag>
          <el-tag v-if="row.typeId == 4" type="danger">暂定清单</el-tag>
        </template>
      </vxe-column>
      <vxe-column field="initNum" title="合同数量" width="140" align="right" :formatter="({cellValue, row}) => numberForamt(cellValue, row.accuracy)"></vxe-column>
      <!-- 注意，不能将修编与变更的数量在一块，此处不能直接使用changed_num -->
      <vxe-column field="checkedChangedNum" title="修编增减" width="140" align="right" :edit-render="{ name: 'MyInputNumber', props: {digitsKey: 'accuracy'} }"></vxe-column>
      <vxe-column field="checkedNum" title="修编后数量" align="right" width="140" :formatter="({cellValue, row}) => numberForamt(cellValue, row.accuracy)"></vxe-column>
      <vxe-column field="num" title="变更后数量" width="140" align="right" :formatter="({cellValue, row}) => numberForamt(cellValue, row.accuracy)"></vxe-column>
      <vxe-column field="changeOrderCode" title="变更令编号" min-width="160"></vxe-column>
    </vxe-table>
  </div>
</template>

<!-- 清单修编-右下清单明细页面 -->
<script>
import debounce from 'lodash/debounce'
import XEUtils from 'xe-utils'
export default {
  data () {
    return {
      dataForm: {
        prjId: '',
        contractId: '',
        subcontractorId: '',
        subItemId: '',
      },
      tableLoading: false,
      tableList: [],
    }
  },
  props: {
    tableHeight: Number,
    locked: Number,
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if (this.dataForm.subItemId) {
          this.getSubBoqData()
        }
      })
    },
    clear() {
      this.tableList = []
    },
    getSubBoqData() {
      this.tableLoading = true
      this.$http.get('/mps/sbs/subBoq', {
          params: {
            ...this.dataForm
          }
        }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.tableList = res.data
        // if (this.tableList == null || this.tableList.length == 0) {
        //   this.$message({
        //     message: '未查询到相关的分项清单。',
        //     type: 'warning',
        //     duration: 2000,
        //   })
        // }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.tableLoading = false
      })
    },

    // 单元格退出编辑事件
    editClosedEvent ({row, column}) {
      const $table = this.$refs.myTbl
      const field = column.property
      // 判断单元格值是否被修改
      if ($table.isUpdateByRow(row, field)) {
        let formData = {
          id: row.id,
          checkedChangedNum: row.checkedChangedNum
        }
        let realNum = XEUtils.add(row.initNum, row.checkedChangedNum)
        row.checkedNum = realNum
        row.num = realNum
        this.$http.put('/mps/sbs/subBoqCheckedNum', formData).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.$emit('calculateSbsMoney', this.dataForm.subItemId)
            }
          })
        }).catch()
      }
    },

    numberForamt(val, digits) {
      if (XEUtils.isNumber(digits)) {
        return XEUtils.commafy(val, { digits: digits })
      } else {
        return val
      }
    },

    // 单元格是否允许编辑
    cellCanEdit(row, column) {
      if (this.$hasPermission('mps:sbs:check:edit') && this.locked < 2 
              && column.property === 'checkedChangedNum' && row.typeId == 1) {
          return true
      }
      return false
    },

    // 单元格是否允许编辑
    beforeEditMethod ({row, rowIndex, column, columnIndex}) {
      return this.cellCanEdit(row, column)
    },

    cellClassName({row, column}) {
      if (this.cellCanEdit(row, column)) {
        return 'cell-underline'
      } else {
        return ''
      }
    },
  }
}
</script>
