<template>
  <el-dialog width="800px" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <table class="paper-table">
        <tr>
          <td class="label" colspan="2">工程名称</td>
          <td width="230px">
            {{dataForm.unitProject}}
          </td>
          <td class="label" width="110px">分部工程名称</td>
          <td width="230px">
            {{dataForm.subProject}}
          </td>
        </tr>
        <tr>
          <td class="label" colspan="2">
            分项工程名称
          </td>
          <td>
            {{dataForm.itemProject}}
          </td>
          <td class="label">
            隐检部位
          </td>
          <td>
            {{dataForm.partName}}
          </td>
        </tr>
        <tr>
          <td class="label" colspan="2">
            隐蔽项目
          </td>
          <td>
            {{dataForm.itemName}}
          </td>
          <td class="label">
            隐蔽日期
          </td>
          <td>
            {{dataForm.concealedDate}}
          </td>
        </tr>
        <tr>
          <td class="label" width="40px">
            隐<br/>蔽<br/>内<br/>容
          </td>
          <td colspan="4" class="textarea">{{dataForm.content}}</td>
        </tr>
        <tr>
          <td class="label">
            施<br/>工<br/>单<br/>位<br/>检<br/>查<br/>情<br/>况
          </td>
          <td colspan="4" class="textarea">{{dataForm.situation}}</td>
        </tr>
        <tr>
          <td class="label">
            隐<br/>蔽<br/>验<br/>收<br/>结<br/>论
          </td>
          <td colspan="4" class="textarea">{{dataForm.conclusion}}</td>
        </tr>
      </table>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        unitProject: '',
        subProject: '',
        itemProject: '',
        partName: '',
        itemName: '',
        concealedDate: '',
        content: '',
        situation: '',
        conclusion: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/concealedWork/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
  }
}
</script>
<style scoped lang="scss">
.paper-table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
  td {
    border: 1px solid;
    padding: 5px;
  }
  td:not(.label) {
    padding: 10px 15px;
  }
  .textarea {
    white-space: pre-wrap;
    vertical-align: top;
  }
  .label {
    text-align: center;
    font-weight: bold;
  }
}
</style>