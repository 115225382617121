<template>
  <el-dialog width="800px" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <table class="paper-table">
        <tr>
          <td class="label" colspan="2">施工部位</td>
          <td width="230px">
            {{dataForm.title}}
          </td>
          <td class="label" width="110px">登记日期</td>
          <td width="230px">
            {{dataForm.recordDate}}
          </td>
        </tr>
        <tr>
          <td class="label" colspan="2">
            天气情况
          </td>
          <td>
            {{dataForm.weather}}
          </td>
          <td class="label">
            风向/风速
          </td>
          <td>
            {{dataForm.windSpeed}}
          </td>
        </tr>
        <tr>
          <td class="label" colspan="2">
            最高/最低温度
          </td>
          <td>
            {{dataForm.temperatures}}
          </td>
          <td class="label">
            记录人
          </td>
          <td>
            {{dataForm.recordEmp}}
          </td>
        </tr>
        <tr>
          <td class="label" width="40px">
            施<br/>工<br/>情<br/>况<br/>记<br/>录
          </td>
          <td colspan="4" class="textarea">{{dataForm.constructionSituation}}</td>
        </tr>
        <tr>
          <td class="label">
            技<br/>术<br/>质<br/>量<br/>安<br/>全<br/>工<br/>作<br/>记<br/>录
          </td>
          <td colspan="4" class="textarea">{{dataForm.qualitySafetyRecord}}</td>
        </tr>
      </table>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        title: '',
        recordDate: '',
        weather: '',
        windSpeed: '',
        temperatures: '',
        constructionSituation: '',
        qualitySafetyRecord: '',
        recordEmp: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/constructionLog/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
  }
}
</script>
<style scoped lang="scss">
.paper-table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
  td {
    border: 1px solid;
    padding: 5px;
  }
  td:not(.label) {
    padding: 10px 15px;
  }
  .textarea {
    white-space: pre-wrap;
    vertical-align: top;
  }
  .label {
    text-align: center;
    font-weight: bold;
  }
}
</style>