<template>
  <el-dialog width="90%" :visible.sync="visible" title="修编台账" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <vxe-table
        size="mini"
        :loading="dataListLoading"
        border
        resizable
        show-overflow="tooltip"
        highlight-hover-row
        max-height="400"
        :scroll-y="{enabled: true}"
        :data="dataList">
      <vxe-column field="subItemName" title="工程名称" min-width="250">
        <template v-slot="{row}">
          <span>{{row.pnames + '/' + row.subItemName}}</span>
        </template>
      </vxe-column>
      <vxe-column field="boqCode" title="清单编码" width="150"></vxe-column>
      <vxe-column field="boqName" title="清单名称" min-width="180"></vxe-column>
      <vxe-column field="unitName" title="单位" width="90" align="center"></vxe-column>
      <vxe-column field="initNum" title="合同数量" width="140" align="right" :formatter="numFormatter"></vxe-column>
      <vxe-column field="checkedNum" title="修编后数量" width="140" align="right" :formatter="numFormatter"></vxe-column>
    </vxe-table>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
    </template>
  </el-dialog>
</template>
<!-- 修编台账 -->
<script>
import XEUtils from 'xe-utils'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        contractId: '',
        subcontractorId: '',
      },
      dataList: [],
      dataListLoading: true,
    }
  },
  methods: {
    init () {
      this.visible = true
      this.getTableData()
    },
    getTableData() {
      this.dataListLoading = true
      this.$http.get(
          '/mps/sbs/editedSubBoq',
          {
            params: {
              contractId: this.dataForm.contractId,
              subcontractorId: this.dataForm.subcontractorId
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
        this.dataListLoading = false
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    // 表格数量格式化
    numFormatter({cellValue, row}) {
      if (XEUtils.isNumber(row.accuracy)) {
        return XEUtils.commafy(cellValue,{digits: row.accuracy})
      } else {
        return cellValue
      }
    },
  }
}
</script>