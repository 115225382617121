<template>
  <el-dialog v-dialog-drag width='70%' :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="监表" prop="tab">
            <el-input v-model="dataForm.tab" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="监理机构" prop="supervise">
            <el-input v-model="dataForm.supervise" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签发人" prop="publisher">
            <el-input v-model="dataForm.publisher" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签发日期" prop="publishDate">
            <el-date-picker type="date" readonly value-format="yyyy-MM-dd" v-model="dataForm.publishDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="内容" prop="content">
            <el-input
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 10}"
              v-model="dataForm.content" readonly>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签收人" prop="signer">
            <el-input v-model="dataForm.signer"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签收日期" prop="signDate">
            <el-date-picker type="date" readonly value-format="yyyy-MM-dd" v-model="dataForm.signDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        code: '',
        tab: '',
        supervise: '',
        publisher: '',
        publishDate: '',
        signer: '',
        signDate: '',
        content: '',
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/superviseInstruction/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>
