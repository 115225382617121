<template>
  <el-dialog width="800px" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <table class="paper-table">
        <tr>
          <td class="label" colspan="2">工程名称</td>
          <td>
            <el-input v-model="dataForm.unitProject" placeholder="工程名称"></el-input>
          </td>
          <td class="label">分部工程名称</td>
          <td>
            <el-input v-model="dataForm.subProject" placeholder="分部工程名称"></el-input>
          </td>
        </tr>
        <tr>
          <td class="label" colspan="2">
            分项工程名称
          </td>
          <td>
            <el-input v-model="dataForm.itemProject" placeholder="分项工程名称"></el-input>
          </td>
          <td class="label">
            隐检部位
          </td>
          <td>
            <el-input v-model="dataForm.partName" placeholder="隐检部位"></el-input>
          </td>
        </tr>
        <tr>
          <td class="label" colspan="2">
            <span style="color: red">*</span>隐蔽项目
          </td>
          <td>
            <el-form-item prop="itemName" size="small">
              <el-input v-model="dataForm.itemName" placeholder="隐蔽项目"></el-input>
            </el-form-item>
          </td>
          <td class="label">
            <span style="color: red">*</span>隐蔽日期
          </td>
          <td>
            <el-form-item prop="concealedDate" size="small">
              <el-date-picker type="date" placeholder="隐蔽日期" value-format="yyyy-MM-dd" v-model="dataForm.concealedDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="label" width="40px">
            隐<br/>蔽<br/>内<br/>容
          </td>
          <td colspan="4">
            <el-input type="textarea" v-model="dataForm.content" placeholder="隐蔽内容" maxlength="1000" show-word-limit :autosize="{ minRows: 5}"></el-input>
          </td>
        </tr>
        <tr>
          <td class="label" width="40px">
            施<br/>工<br/>单<br/>位<br/>检<br/>查<br/>情<br/>况
          </td>
          <td colspan="4">
            <el-input type="textarea" v-model="dataForm.situation" placeholder="施工单位检查情况" maxlength="1000" show-word-limit :autosize="{ minRows: 7}"></el-input>
          </td>
        </tr>
        <tr>
          <td class="label" width="40px">
            隐<br/>蔽<br/>验<br/>收<br/>结<br/>论
          </td>
          <td colspan="4">
            <el-input type="textarea" v-model="dataForm.conclusion" placeholder="隐蔽验收结论" maxlength="1000" show-word-limit :autosize="{ minRows: 5}"></el-input>
          </td>
        </tr>
      </table>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        unitProject: '',
        subProject: '',
        itemProject: '',
        partName: '',
        itemName: '',
        concealedDate: '',
        content: '',
        situation: '',
        conclusion: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      }
    }
  },
  computed: {
    dataRule () {
      return {
        itemName: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        concealedDate: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/concealedWork/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/concealedWork/', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {})
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>
<style scoped lang="scss">
.paper-table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
  td {
    border: 1px solid;
  }
  .label {
    text-align: center;
    font-weight: bold;
  }
}
::v-deep .el-form-item {
  margin-bottom: 0 !important;
}
</style>