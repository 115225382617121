<template>
  <el-dialog width="800px" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <table class="paper-table">
        <tr>
          <td class="label" colspan="2"><span style="color: red">*</span>施工部位</td>
          <td>
            <el-form-item prop="title" size="small">
              <el-input v-model="dataForm.title" placeholder="施工部位"></el-input>
            </el-form-item>
          </td>
          <td class="label"><span style="color: red">*</span>登记日期</td>
          <td>
            <el-form-item prop="recordDate" size="small">
              <el-date-picker type="date" placeholder="登记日期" value-format="yyyy-MM-dd" v-model="dataForm.recordDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="label" colspan="2">
            天气情况
          </td>
          <td>
            <el-input v-model="dataForm.weather" placeholder="天气情况"></el-input>
          </td>
          <td class="label">
            风向/风速
          </td>
          <td>
            <el-input v-model="dataForm.windSpeed" placeholder="风向/风速"></el-input>
          </td>
        </tr>
        <tr>
          <td class="label" colspan="2">
            最高/最低温度
          </td>
          <td>
            <el-input v-model="dataForm.temperatures" placeholder="最高/最低温度"></el-input>
          </td>
          <td class="label">
            <span style="color: red">*</span>记录人
          </td>
          <td>
            <el-form-item prop="recordEmp" size="small">
              <el-input v-model="dataForm.recordEmp" placeholder="记录人"></el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td class="label" width="40px">
            施<br/>工<br/>情<br/>况<br/>记<br/>录
          </td>
          <td colspan="4">
            <el-input type="textarea" v-model="dataForm.constructionSituation" placeholder="施工情况记录" maxlength="1000" show-word-limit :autosize="{ minRows: 5}"></el-input>
          </td>
        </tr>
        <tr>
          <td class="label">
            技<br/>术<br/>质<br/>量<br/>安<br/>全<br/>工<br/>作<br/>记<br/>录
          </td>
          <td colspan="4">
            <el-input type="textarea" v-model="dataForm.qualitySafetyRecord" placeholder="技术质量安全工作记录" maxlength="1000" show-word-limit :autosize="{ minRows: 8}"></el-input>
          </td>
        </tr>
      </table>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import XEUtils from 'xe-utils'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        title: '',
        recordDate: '',
        weather: '',
        windSpeed: '',
        temperatures: '',
        constructionSituation: '',
        qualitySafetyRecord: '',
        recordEmp: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      }
    }
  },
  computed: {
    dataRule () {
      return {
        title: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        recordDate: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        recordEmp: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          // 设置时间
          this.dataForm.recordDate = XEUtils.toDateString(new Date())
          // 默认姓名
          this.dataForm.recordEmp = this.$store.state.user.realName
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/constructionLog/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/constructionLog/', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {})
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>
<style scoped lang="scss">
.paper-table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
  td {
    border: 1px solid;
  }
  .label {
    text-align: center;
    font-weight: bold;
  }
}
::v-deep .el-form-item {
  margin-bottom: 0 !important;
}
</style>