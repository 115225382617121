<template>
  <el-dialog top="5vh" width="90%" :visible.sync="visible" :title="title" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-row :gutter="30">
      <el-col :span="12">
        <el-table
          :data="sbsTableData"
          highlight-current-row
          border
          :max-height="tableHeight"
          style="width: 100%;"
        >
          <el-table-column
            v-for="(item, index) in sbsTableColumn"
            :key="index"
            :prop="item.fieldName + item.boqIndex"
            header-align="center"
            align="center"
            :width="item.width"
            :fixed="item.boqIndex ? false : true"
          >
            <template v-slot:header>
              {{item.name + item.boqIndex}}
<!--              <i v-if="index == (sbsTableColumn.length - 1) && index > sbsTableBoqNumColStartIndex" class="el-icon-remove-outline" style="color:red;cursor:pointer;" @click="deleteSbsColumn(index)"></i>-->
<!--              <i v-if="index == (sbsTableColumn.length - 1)" class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;" @click="addSbsColumn(index)"></i>-->
            </template>
            <template v-slot="{row, $index}">
              <el-input-number v-if="item.boqIndex" v-model="row[item.fieldName + item.boqIndex]" :controls="false" style="width: 100%" placeholder="请填写数量"></el-input-number>
              <el-input v-else-if="item.fieldName" v-model="row[item.fieldName + item.boqIndex]"></el-input>
              <span v-else>
                <i v-if="$index == sbsTableData.length - 1" class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;" @click="addSbsRow()"></i>{{ $index + 1 }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <br/>
        <el-table
          :data="boqTableData"
          border
          :max-height="tableHeight"
          highlight-current-row
          :select-on-indeterminate="false"
        >
          <el-table-column type="index" prop="index" label="" width="90" header-align="center" align="center" show-overflow-tooltip>
            <template v-slot="{row, $index}">
              {{`清单${$index + 1}`}}
              <i class="el-icon-remove" style="color:red;cursor:pointer;" @click="deleteBoqRow(row)"></i>
            </template>
          </el-table-column>
          <el-table-column prop="code" label="编号" width="120" align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="name" label="名称" align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="unitName" label="单位" width="100" header-align="center" align="center" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="price" label="单价" width="100" header-align="center" align="right" show-overflow-tooltip></el-table-column> -->
        </el-table>
      </el-col>
      <el-col :span="12">
        <vxe-table
            size="mini"
            row-id="id"
            :loading="boqTableDataLoading"
            ref="boqLeafTable"
            border
            resizable
            stripe
            :max-height="tableHeight * 2 - 50"
            show-overflow="tooltip"
            @checkbox-change="boqLeafTableCheckboxChangeHandle"
            highlight-hover-row
            :scroll-y="{enabled: true}"
            :data="boqLeafData">
          <vxe-column type="checkbox" width="55" align="center"></vxe-column>
          <vxe-column field="code" title="清单编号" width="150" align="left"></vxe-column>
          <vxe-column field="name" title="清单名称" min-width="220" align="left"></vxe-column>
          <vxe-column field="unitName" title="单位" width="80" align="center"></vxe-column>
          <vxe-column field="num" title="数量" width="100" align="right"></vxe-column>
        </vxe-table>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// 清单修编
import debounce from 'lodash/debounce'
export default {
  data() {
    return {
      visible: false,
      parentSbsId: '',
      contractId: '',
      subcontractId: '',
      tableHeight: 300,
      sbsTableData: [{},{},{},{}],
      // 左上方sbstable中，清单数量列的起始index（因sbs列目前仅有name，后续可能会进行修改，导致清单列的起始index发生变化，便于各处判断）
      sbsTableBoqNumColStartIndex: 2,
      sbsTableColumn: [
        {
          name: '',
          boqIndex: '',
          width: 50,
        },
        {
          name: '名称',
          fieldName: 'sbsName',
          boqIndex: '',
          width: 220,
        },
      ],
      boqTableData: [],
      boqLeafData: [],
      // 保存列表页面父节点的row数据，用于新增后刷新父节点
      row: {},
      title: '批量插入下级',
      boqTableDataLoading: false,
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getBoqLeafData()
      })
    },
    // 获取信息
    getBoqLeafData() {
      this.boqTableDataLoading = true
      this.$http.get(`/mps/sbs/boqLeafList/${this.contractId}`).then(({data: res}) => {
        this.boqTableDataLoading = false
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.boqLeafData = res.data
        // 查询并继承父节点的清单
        this.$nextTick(() => {
          this.extendParentBoq()
        })
      }).catch(() => {
        this.boqTableDataLoading = false
      })
    },
    // sbs表格删除列
    deleteSbsColumn() {
      this.sbsTableColumn.pop()
    },
    // sbs表格增加列
    addSbsColumn(index) {
      this.sbsTableColumn.push({
        name: '清单',
        fieldName: 'boq',
        boqIndex: index + 2 - this.sbsTableBoqNumColStartIndex,
        width: 130,
      })
    },
    // sbs增加行
    addSbsRow() {
      this.sbsTableData.push({})
    },
    boqLeafTableCheckboxChangeHandle({records}) {
      this.boqTableData = records
      this.adjustSbsTableBoqColumn()
    },
    // 继承父节点清单
    extendParentBoq() {
      this.$http.get(`/mps/sbs/subBoqList/${this.parentSbsId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let rows = []
        for (let subBoq of res.data) {
          let row = this.$refs.boqLeafTable.getRowById(subBoq.boqId)
          if (row) {
            rows.push(row)
          }
        }
        // 将右侧表格对应的行设为已勾选
        this.$refs.boqLeafTable.setCheckboxRow(rows, true)
        // 将右侧表格所有选中的行同步到左下表格
        this.boqTableData = this.$refs.boqLeafTable.getCheckboxRecords(true)
        this.adjustSbsTableBoqColumn()
      }).catch(() => {
        this.$message.error('出错了')
      }).finally(() => {
      })
    },
    // boq表格删除行
    deleteBoqRow(row) {
      // 将右侧表格对应勾选的行设为未勾选
      this.$refs.boqLeafTable.setCheckboxRow(row, false)
      // 将右侧表格所有选中的行同步到左下表格
      this.boqTableData = this.$refs.boqLeafTable.getCheckboxRecords(true)
      this.adjustSbsTableBoqColumn()
    },
    // 根据选中的清单行数，动态调整左上sbsTable的清单列数
    adjustSbsTableBoqColumn() {
      let sbsTableBoqCols = this.sbsTableColumn.length - this.sbsTableBoqNumColStartIndex
      let boqTableDataLength = this.boqTableData.length
      if (sbsTableBoqCols > boqTableDataLength) {
        for (let i = sbsTableBoqCols; i > boqTableDataLength; i--) {
          this.deleteSbsColumn()
        }
      } else if (sbsTableBoqCols < boqTableDataLength) {
        for (let i = sbsTableBoqCols; i < boqTableDataLength; i++) {
          this.addSbsColumn(i + 1)
        }
      }
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      const loading = this.$loading({
        lock: true,
        text: '正在处理数据...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.post(
          '/mps/sbs/editCheckBatchAddChildren',
          {
            parentSbsId: this.parentSbsId,
            contractId: this.contractId,
            subcontractorId: this.subcontractorId,
            sbsList: this.sbsTableData,
            boqList: this.boqTableData
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('addNode', this.row)
          }
        })
      }).catch(() => {
      }).finally(() => {
        loading.close()
      })
    }, 1000, {'leading': true, 'trailing': false}),
  }
}
</script>