<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__preworklib">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="add" @click="addChildNode({id: 0,name: '无'})" v-if="$hasPermission('opm:preworklib:save')"></toolbar-button>
            <toolbar-button role="delete" @click="myDeleteHandle()" v-if="$hasPermission('opm:preworklib:delete')"></toolbar-button>
          </el-button-group>
          <el-button-group style="margin-top: -3px;">
            <el-tooltip content="刷新名称路径" placement="top" effect="light">
              <el-button icon="el-icon-refresh" v-if="$hasPermission('opm:preworklib:update')" @click="refreshHandle"></el-button>
            </el-tooltip>
            <el-tooltip content="全局排序" placement="top" effect="light">
              <el-button icon="el-icon-sort" v-if="$hasPermission('opm:preworklib:update')" @click="refreshSortNoHandle"></el-button>
            </el-tooltip>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
          v-if="tableShow"
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          row-key="id"
          lazy
          :load="loadTableData"
          @selection-change="dataListSelectionChangeHandle"
          highlight-current-row
          border
          :max-height="maxTableHeight"
          style="width: 100%;"
          :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="code" label="编码" width="250" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="名称" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.name }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="250" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="160">
          <template slot-scope="{row}">
            <table-button role="addChild" @click="addChildNode(row)" v-if="$hasPermission('opm:preworklib:save')"></table-button>
            <table-button role="edit" @click="addOrUpdateHandle(row.id)" v-if="$hasPermission('opm:preworklib:update')"></table-button>
            <table-button role="delete" @click="myDeleteHandle(row.id, row.pid)" v-if="$hasPermission('opm:preworklib:delete')"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @hide="closeDialogHandle" @addNode="addNodeCallback" @updateNode="updateNodeCallback"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './preworklib-add-or-update'
import ViewPage from './preworklib-view'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        deleteURL: '/opm/preWorkLib',
        deleteWithRelatedFiles: false,
        deleteSuccessCallback: this.deleteSuccessCallback
      },
      dataForm: {
        name: '',
        code: '',
      },
      maxTableHeight: 800,

      // 存储已加载的节点的map
      treeNodeMap: new Map(),
      tableLoading: false,
      tableData: [],
      tableShow: true,
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.maxTableHeight = window.innerHeight - 160
    })
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    getDataList() {
      this.treeNodeMap = new Map()
      // 重置一些el-table缓存的变量
      // 此处如果不重置，可能会导致一些无法预料的情况
      // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
      this.tableShow = false
      this.$nextTick(() => {
        this.tableShow = true
      })
      this.tableLoading = true
      this.$http.get('/opm/preWorkLib/children',
          {
            params: {
              pid: 0
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.tableData = res.data
        this.tableLoading = false
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    // el-table懒加载节点
    loadTableData(data, node, resolve) {
      let pid = data.id
      // 将已加载的节点相关参数存入map，用于后续增加子节点、删除子节点时刷新父节点
      this.treeNodeMap.set(pid, {data, node, resolve})
      this.$http.get('/opm/preWorkLib/children',
          {
            params: {
              'pid': pid,
              ...this.dataForm
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        resolve(res.data);
        // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
        // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
        if (res.data.length == 0) {
          this.$set(this.$refs.table.store.states.lazyTreeNodeMap, pid, [])
        }
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    addNodeCallback(pid) {
      this.refreshParentNode(pid)
    },
    updateNodeCallback(pid, isPidChanged) {
      if (isPidChanged) {
        // 父节点修改过，直接刷新列表
        this.getDataList()
      } else {
        this.refreshParentNode(pid)
      }
    },
    // 刷新父节点（只能刷新展开过的节点）
    refreshParentNode(pid) {
      let nodeInfo = this.treeNodeMap.get(pid)
      if (nodeInfo) {
        // 这个loading属性控制此节点在load时是否重新加载
        // 例：在删除某个子节点后，若父节点loading属性为false，则父节点重新加载后，被删除的子节点依然显示
        nodeInfo.node.loading = true
        nodeInfo.data.hasChildren = true // 设置父节点为非叶子节点，出现展开按钮
        this.loadTableData(nodeInfo.data, nodeInfo.node, nodeInfo.resolve)
      } else {
        this.getDataList()
      }
    },
    // 新增子节点
    addChildNode(row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.pid = row.id
        this.$refs.addOrUpdate.dataForm.parentName = row.name
        this.$refs.addOrUpdate.init()
      })
    },
    deleteSuccessCallback({id,pid}) {
      if (id && pid) {
        this.refreshParentNode(pid)
      } else {
        this.getDataList()
      }
    },
    // 删除
    myDeleteHandle(id, pid) {
      this.deleteHandle(id,
          {
            deleteSuccessCallbackArgs: {id, pid},
            promptMessage: '同时会删除下级节点',
            promptTitle: '确定进行[删除]操作?',
            autoQuery: false
          }
      )
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.init()
      })
    },

    // 全局排序
    refreshSortNoHandle() {
      this.$confirm('是否要运行全局排序?').then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put(`/opm/preWorkLib/refreshSortNo`, this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '已完成',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.getDataList()
            }
          })
        }).finally(() => {
          loading.close()
        })
      }).catch()
    },

    // 递归刷新 pids,pnames
    refreshHandle() {
      this.$confirm('您确定要刷新名称路径吗？').then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put(`/opm/preWorkLib/refresh`).then(({data: res}) => {
          if (res.code != 0) {
            return this.$alert(res.msg, '', {
              confirmButtonText: '关闭',
              type: "error"
            })
          }
          this.$alert('已全部刷新！', '', {
            confirmButtonText: '关闭',
            type: "success"
          })
        }).finally(() => {
          loading.close()
        })
      }).catch()
    },
  }
}
</script>