<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader ref="contractCascader" include-sub @change="contractChange" style="width: 96%;"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree lazy v-if="leftTreeVisible"
            class="left-tree"
            node-key="id" v-loading="leftTreeLoading"
            :data="leftTreeData"
            :props="leftTreeProps"
            :load="loadLeftTreeNode"
            :expand-on-click-node="false"
            @node-click="leftTreeNodeClick"
            :default-expanded-keys="expandedKeys"
            :highlight-current="true">
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__meas">
          <el-form :inline="true">
            <el-form-item>
              <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
                <toolbar-button name="锁定" v-if="locked < 2"  @click="lock()" :disabled="!$hasPermission('mps:sbs:check:lock')" key="1"></toolbar-button>
                <toolbar-button name="查看修编台账" @click="viewModifiedTable()" key="2"></toolbar-button>
                <toolbar-button role="unlock" v-if="locked >= 2" @click="unlock()" :disabled="!$hasPermission('mps:sbs:check:unlock')" key="3"></toolbar-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <vxe-table
                v-if="rightTopTableShow"
                :loading="rightTopDataLoading"
                ref="xTable"
                size="mini"
                :height="rightTopTableHeight"
                border
                keep-source
                auto-resize
                show-overflow
                :row-config="{isHover: true, isCurrent: true, keyField: 'id'}"
                :column-config="{resizable: true}"
                :tree-config="treeConfig"
                :edit-config="{trigger: 'click', mode: 'cell', showStatus: true, beforeEditMethod: beforeEditMethod}"
                :data="rightTopTableData"
                @current-change="changeCurrentSbsHandle"
                :footer-method="footerMethod"
                :show-footer="showFooter"
                footer-cell-class-name="mytable-footer-summary">
            <vxe-column field="name" min-width="300" title="名称" tree-node></vxe-column>
            <vxe-column field="code" min-width="170" title="编号"></vxe-column>
            <vxe-column field="unitName" width="100" title="单位" align="center"></vxe-column>
            <vxe-column field="num1" width="140" title="设计数量1" align="right"></vxe-column>
            <vxe-column field="num2" width="140" title="设计数量2" align="right"></vxe-column>
            <vxe-column field="money" width="140" title="合同金额（元）" align="right" :formatter="moneyFormatter"></vxe-column>
            <vxe-column width="100" title="操作" align="center">
              <template #default="{ row }">
                <el-button v-if="canRevision(row)" type="text" @click="addRevisionNodes(row)">[修编拆分]</el-button>
              </template>
            </vxe-column>
          </vxe-table>
          <sbs-edit-check-boq :locked="locked" v-if="sbsEditCheckBoqVisible" ref="sbsEditCheckBoq" :tableHeight="rightBottomTableHeight" @calculateSbsMoney="calculateSbsMoneyHandle"></sbs-edit-check-boq>
          <sbs-edit-check-report v-if="sbsEditCheckReportVisible" ref="sbsEditCheckReport" @close="closeDialogHandle"></sbs-edit-check-report>
          <add-children v-if="addChildrenVisible" ref="addChildren" @addNode="addNodeCallback" @close="closeAddChildrenHandle"></add-children>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<!-- 清单修编 -->
<script>
import XEUtils from 'xe-utils'
import SbsEditCheckBoq from './sbs-edit-check-boq'
import SbsEditCheckReport from "@/views/modules/mps/sbs-edit-check-report"
// 清单修编
import AddChildren from './sbs-edit-check-add-children'
export default {
  data () {
    return {
      dataForm: {
        prjId: '',
        contractId: '',
        subcontractorId: ''
      },

      // 左侧的树
      leftTreeVisible: false,
      leftTreeLoading: false,
      leftTreeData: [],
      leftTreeProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'isLeaf'
      },
      expandedKeys: [],

      // 右上标段分项树
      treeConfig: {
        transform: false,
        rowField: 'id',
        parentField: 'pid',
        // line: true,
        lazy: true,
        hasChild: 'hasChildren',
        loadMethod: this.loadRightTopChildren,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus'
      },
      rightTopTableData: [],
      rightTopTableShow: true,
      rightTopTableHeight: 400,
      rightTopDataLoading: false,
      currentSbsRow: null,

      // 右下清单明细
      sbsEditCheckBoqVisible: false,
      // 修编台账弹窗
      sbsEditCheckReportVisible: false,
      // 拆分细目弹窗
      addChildrenVisible: false,
      rightBottomTableHeight: 350,
      locked: 0,
      amountAccuracy: 0, // 金额小数位精度
      showFooter: false,
    }
  },
  components: {
    SbsEditCheckBoq,
    SbsEditCheckReport,
    AddChildren,
  },
  mounted () {
    this.$nextTick(() => {
      this.rightTopTableHeight = window.innerHeight - this.rightBottomTableHeight - 170
      if (this.rightTopTableHeight < 250) {// 如果高度太小，则重新调整上下版面的高度
        this.rightBottomTableHeight = 250
        this.rightTopTableHeight = window.innerHeight - this.rightBottomTableHeight - 170
      }
    })
  },
  methods: {
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      this.amountAccuracy = data.contract.amountAccuracy
      this.leftTreeVisible = true
      this.locked = data.contract.lockSubBoq === null ? 0 : data.contract.lockSubBoq
      if (data.subcontractor) {
        this.locked = data.subcontractor.lockSubBoq === null ? 0 : data.subcontractor.lockSubBoq
      }
      // 加载左侧标段分项结构树
      this.getLeftTreeRootData();
      // 加载右上页面标段分项根节点
      this.getRightTopRootData(0)
      // 清空并隐藏右下页面
      this.clearSubBoqTable()
    },

    lock() {
      this.$confirm(`确认要锁定分项清单吗？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let url = ''
        if (this.dataForm.subcontractorId) {
          url = `/mps/sbs/lockSubBoqSubcontractor/${this.dataForm.subcontractorId}/2`
        } else {
          url = `/mps/sbs/lockSubBoq/${this.dataForm.contractId}/2`
        }
        this.$http.put(url).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.locked = 2
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          if (this.hasSubcontractor) {
            this.$refs.contractCascader.updateSubcontractorData(this.dataForm.subcontractorId, "lockSubBoq", 2)
          } else {
            this.$refs.contractCascader.updateContractData(this.dataForm.contractId, "lockSubBoq", 2)
          }
          this.$message({
            message: '已锁定',
            type: 'warning',
            duration: 1500,
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(e => e)
    },
    unlock() {
      this.$confirm(`确定要解锁吗？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let url = ''
        if (this.dataForm.subcontractorId) {
          url = `/mps/sbs/unlockSubBoqSubcontractor/${this.dataForm.subcontractorId}/1`
        } else {
          url = `/mps/sbs/unlockSubBoq/${this.dataForm.contractId}/1`
        }
        this.$http.put(url).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          if (this.hasSubcontractor) {
            this.$refs.contractCascader.updateSubcontractorData(this.dataForm.subcontractorId, "lockSubBoq", 1)
          } else {
            this.$refs.contractCascader.updateContractData(this.dataForm.contractId, "lockSubBoq", 1)
          }
          this.locked = 1
          this.$message({
            message: '已解锁',
            type: 'warning',
            duration: 1500,
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(e => e)
    },
    viewModifiedTable() {
      this.sbsEditCheckReportVisible = true
      this.$nextTick(() => {
        this.$refs.sbsEditCheckReport.dataForm.contractId = this.dataForm.contractId
        this.$refs.sbsEditCheckReport.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.sbsEditCheckReport.init()
      })
    },
    closeDialogHandle() {
      this.sbsEditCheckReportVisible = false
    },
    // 是否允许在该节点上进行清单修编
    canRevision(row) {
      if (this.locked >= 2) {
        return false
      }
      // 普通的项目节叶子节点，或者已经有清单修编了的项目节非叶子节点
      return row.isSbsLeaf || (row.isLeaf && row.level != 99)
    },
    // 清单修编
    addRevisionNodes(row) {
      this.addChildrenVisible = true
      this.$nextTick(() => {
        // 此处将row传给子组件，作为新增后的回调方法的参数
        this.$refs.addChildren.row = row
        this.$refs.addChildren.parentSbsId = row.id
        this.$refs.addChildren.contractId = this.dataForm.contractId
        this.$refs.addChildren.subcontractorId = this.dataForm.subcontractorId
        this.$refs.addChildren.title = row.name + (row.code ? `(${row.code})` : '')
        this.$refs.addChildren.init()
      })
    },
    closeAddChildrenHandle() {
      this.addChildrenVisible = false
    },
    addNodeCallback(row) {
      // 拆分细目后刷新被拆分的节点
      row.hasChildren = true
      row.isLeaf = false
      this.$refs.xTable.reloadTreeExpand(row)
      // todo 左侧树理论上也需要刷新，暂时没有好方案
    },

    // 加载左侧标段分项结构树
    getLeftTreeRootData() {
      this.leftTreeLoading = true
      this.leftTreeData = []
      this.$http.get('/mps/sbs/children',
        {
          params: {
            pid: 0,
            ...this.dataForm
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data && res.data.length > 0) {
          this.leftTreeData = [
            {
              id: 0,
              pid: -1,
              name: '全部',
              isLeaf: false,
              children: res.data
            }
          ]
          // 实现默认展开第一级节点
          this.expandedKeys = [0]
        } else {
          this.leftTreeData = []
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.leftTreeLoading = false
      })
    },

    // 异步加载左侧树的下级节点
    loadLeftTreeNode(node, resolve) {
      if (node.data && node.level >= 1) {
        let pid = node.data.id
        this.leftTreeLoading = true
        this.$http.get('/mps/sbs/children',
          {
            params: {
              pid: pid,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data);
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.leftTreeLoading = false
        })
      }
    },

    // 点击左侧标段分项树节点，过滤查询右上页面的标段分项数据
    leftTreeNodeClick(data, node, ele) {
      // 过滤加载右上页面标段分项的节点
      this.getRightTopRootData(data.id)
      // 清空并隐藏右下页面
      this.clearSubBoqTable()
      // 修编细目的上级节点也可能挂接了零号清单，所以也需要显示
      if ((data.isSbsLeaf == 1 && data.isLeaf == 0) || data.isLeaf == 1) {
        this.currentSbsRow = data
        this.showSubBoqTable(data.id, data.code, data.name)
      }
    },

    // 加载右上页面标段分项根节点
    getRightTopRootData(pid) {
      this.rightTopTableData = []
      this.rightTopTableShow = false
      this.$nextTick(() => {
        this.rightTopTableShow = true
      })
      if (this.dataForm.prjId) {
        this.rightTopDataLoading = true
        // 注意：点击左侧节点加载右侧数据的时候，为了方便用户使用，不光是加载下级节点，也会将点击的节点包括在内
        this.$http.get('/mps/sbs/meAndChildren',
          {
            params: {
              pid: pid,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.rightTopTableData = res.data
          if (this.rightTopTableData && this.rightTopTableData.length == 1) {
            // 如果只查到一条分项，则默认选中
            this.$nextTick(() => {// 注意：必须使用nextTick，以便在xTable的DOM更新后，才能确保成功getRowById调用成功
              //let currentRow = this.$refs.xTable.getRowById(res.data[0].id)
              //this.$refs.xTable.setCurrentRow(currentRow)
              //this.changeCurrentSbsHandle({newValue: res.data[0], row: currentRow})
            })
          }

          if (this.rightTopTableData && this.rightTopTableData.length > 1) {
            this.showFooter = true
          } else {
            this.showFooter = false
          }
          this.rightTopDataLoading = false
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }
    },
    // 异步加载右上标段分项子节点
    loadRightTopChildren({row}) {
      return new Promise(resolve => {
        this.$http.get('/mps/sbs/children',
          {
            params: {
              pid: row.id,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data)
        }).catch()
      })
    },
    // 右上页面点击不同的节点，查询右下页面对应的工程量清单
    changeCurrentSbsHandle({newValue, row}) {
      this.currentSbsRow = row
      this.showSubBoqTable(newValue.id, row.code, row.name)
    },

    // 加载右下版面的零号清单
    showSubBoqTable(subItemId, subItemCode, subItemName) {
      let msg = subItemCode ? (subItemCode + ' ' + subItemName) : subItemName
      if (msg && msg.length > 0) {
        this.$message({
          message: `正在查询【${msg}】的分项清单。`,
          type: 'success',
          duration: 3000,
        })
        this.sbsEditCheckBoqVisible = true
        this.$nextTick(() => {
          this.$refs.sbsEditCheckBoq.dataForm.prjId = this.dataForm.prjId
          this.$refs.sbsEditCheckBoq.dataForm.contractId = this.dataForm.contractId
          this.$refs.sbsEditCheckBoq.dataForm.subcontractorId = this.dataForm.subcontractorId
          this.$refs.sbsEditCheckBoq.dataForm.subItemId = subItemId
          this.$refs.sbsEditCheckBoq.init()
        })
      }
    },

    // 清空右下的合同清单表格
    clearSubBoqTable() {
      if (this.sbsEditCheckBoqVisible) {
        this.$refs.sbsEditCheckBoq.clear()
        this.sbsEditCheckBoqVisible = false
      }
    },
    // 分项清单的复核数量被修改以后，刷新标段分项上的合同额
    calculateSbsMoneyHandle(sbsId) {
      // 后台递归汇总计算分项各层级的合同金额
      this.$http.put(`/mps/sbs/sbsMoney/${sbsId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        // 异步刷新页面中对应标段分项上的合同金额
        XEUtils.set(this.currentSbsRow, 'money', res.data)
        // 异步刷新页面中项目节各层级的合同金额
        this.sumEachTreeNode(this.$refs.xTable, this.currentSbsRow, 'money')
      }).catch(() => {
        return this.$message.error('计算项目节合同额时出现错误！')
      })
    },
    
    // 递归计算下级节点的金额
    handleSummary (children, fieldName) {
      if (fieldName === 'money') {
        return {
          //money: Math.floor(XEUtils.sum(children, 'money'))
          money: XEUtils.sum(children, 'money')
        }
      }
    },

    // 遍历标段分项树，重新每一级节点的计算金额
    sumEachTreeNode(tbl, currentRow, field) {
      XEUtils.eachTree(tbl.getTableData().fullData, (row,index,items,path,parent,nodes) => {
        let children = row.children
        if (children && children.length) {
          // 合计子节点
          Object.assign(row, this.handleSummary(children, field))
          // this.refreshMyRow(tbl, row, field)
        } else {
          if (index === items.length - 1) {
            // 全量汇总
            for (let len = nodes.length - 2; len >= 0; len--) {
              Object.assign(nodes[len], this.handleSummary(nodes[len].children, field))
              // this.refreshMyRow(tbl, nodes[len], field)
            }
          }
        }
      },{
        children: 'children',
        accordion: false, // 一层只允许展开一个节点
        expandAll: false // 默认是否全部展开
      })
      // this.refreshMyRow(this.$refs.xTable, currentRow, field)
    },

    /**
     * 恢复数据行的状态（不标记小三角）
     * @param table vxe table组件对象
     * @param row 行对象(最新修改的数据)
     * @param field 字段名
     */
     refreshMyRow($table, row, field) {
      if ($table && row && field) {
        const {keepSource, tableSourceData, tableData} = $table
        if (keepSource) { // vxe table开启了 keep-source
          const newValue = XEUtils.get(row, field)
          let resultData = XEUtils.findTree(tableSourceData,item => item.id === row.id, {children: 'children'})
          if (resultData) {
            XEUtils.set(row, field, newValue)
            XEUtils.set(resultData.item, field, newValue)
          }
          $table.tableData = tableData.slice(0)
        }
      }
    },

    // 合计
    footerMethod({columns, data }) {
      const footerData = [
        columns.map((column, _columnIndex) => {
          if (_columnIndex === 0) {
            return '合计'
          }
          if (['money'].includes(column.field)) {
            return this.getFooterTotal(data, 'money')
          }
          return null
        }),
      ]
      return footerData
    },
    // 计算底部合计行中的相关金额
    getFooterTotal(data, columnName) {
      // 没有对data进行递归，仅计算了根节点（data中有children属性）
      if (columnName == 'money') {
        let total = 0
        for (let i = 0; i < data.length; i++) {
          total = XEUtils.add(total, data[i].money)
        }
        if (XEUtils.isNumber(this.amountAccuracy)) {
          return XEUtils.commafy(total, { digits: this.amountAccuracy })
        } else {
          return total
        }
      }
    },

    // 金额格式化
    moneyFormatter ({cellValue}) {
      return XEUtils.commafy(cellValue)
    },

    //  树节点是否可编辑
    beforeEditMethod ({row,rowIndex,column,columnIndex}) {
      // 清单修编中暂不允许修改项目节上的设计数量、金额等字段
      return false
    },
  }
}
</script>
<style lang="scss" scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 160px);
  }
  ::v-deep .mytable-footer-summary {
    background-color: #D4E6F1;
    font-weight: bold;
  }
</style>